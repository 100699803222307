import { Injectable } from "@angular/core";
import { CUSTOMER_PORTAL_CONSTANT } from "src/app/constant/constant";
import { AddToCart, CheckoutStatus, DiscardOrderRequest, RemoveWishList } from "../classes/order";
import { GlobalService } from "../services/global.service";

@Injectable({
    providedIn: 'root',
})
export class CommonUtil {
    constructor(private globalService: GlobalService) {

    }

    public translateLanguage(languageSelected: string): void {
        this.globalService.translate(languageSelected);
    }
    public static getUserDetails():any {
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        if(currentUser){
            return currentUser
        }
    }
    public static getStoreIdFromLocalStorageUserProfile(key: any): any {        
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
        if(key === 'store_id' && (currentUser === null || currentUser === undefined)) {
            return 0;
        } else if(key === 'store_id' && currentUser && currentUser?.store_id) {
            return (currentUser?.store_id) ? currentUser?.store_id : 0;
        } else if(key === 'customer_id' && (currentUser === null || currentUser === undefined)) {
            return 0;
        } else if(key === 'customer_id' && currentUser && currentUser?.customer_id) {
            return (currentUser?.customer_id) ? currentUser?.customer_id : 0;
        }
    }

    public static getShoppingType(requestType: string): string {
        if (requestType === 'addCart') {
            return CUSTOMER_PORTAL_CONSTANT.SHOPPING_CART
        } else return CUSTOMER_PORTAL_CONSTANT.WISH_LIST;
    }

    public static prepareRemoveWishList(product: any): RemoveWishList {        
        let removeWishListRequest: RemoveWishList = {};
        removeWishListRequest.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);        
        removeWishListRequest.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
        removeWishListRequest.product_id = product?.product_price?.ProductId || product?.product_id || product?.product_response_model?.product_price?.ProductId; //11; // My orders lost API based this key will dynamic
        removeWishListRequest.group_id = []; 
        return removeWishListRequest;

    }

    public static prepareAddCart(product: any, quantity?: number): AddToCart {
        console.log('product ---', product);
        let addToCart: AddToCart = {};
        addToCart.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);        
        addToCart.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
        addToCart.product_id = product?.product_price?.ProductId || product?.product_id || product?.product_response_model?.product_price?.ProductId; //11; // My orders lost API based this key will dynamic        
        addToCart.quantity = quantity || 1;
        return addToCart;

    }
    // public static prepareCheckoutStatus(): CheckoutStatus {
    //     let checkoutstatus: CheckoutStatus = {};
    //     checkoutstatus.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);        
    //     checkoutstatus.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
    //     return checkoutstatus;
    // }

    public static prepareRemoveCart(product: any, ShoppingCartType: string): RemoveWishList {
        console.log('product ---', product);
        let discardOrderRequest: RemoveWishList = {};
        // discardOrderRequest.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);
        
        // discardOrderRequest.CustomerId = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
        // discardOrderRequest.ProductId = product?.product_price?.ProductId || product?.product_id || product?.product_response_model?.product_price?.ProductId; //11; // My orders lost API based this key will dynamic
        // //discardOrderRequest.quantity = 2; // My orders lost API based this key will dynamic
        // discardOrderRequest.ShoppingCartType = ShoppingCartType; // My orders lost API based this key will dynamic
        // //discardOrderRequest.Id = product.Id || product?.product_response_model?.Id; //1; // My orders lost API based this key will dynamic
        // discardOrderRequest.PageIndex = CUSTOMER_PORTAL_CONSTANT.DEFAULT_PAGE_INDEX;
        // discardOrderRequest.PageSize = CUSTOMER_PORTAL_CONSTANT.DEFAULT_PAGE_SIZE;
        // discardOrderRequest.ProductSorting = CUSTOMER_PORTAL_CONSTANT.DEFAULT_SORTING;
        return discardOrderRequest;

    }

    public static prepareRemoveGroupCartWishList(product: any, ShoppingCartType: string): DiscardOrderRequest {
        console.log('product ---', product);
        let discardOrderRequest: DiscardOrderRequest = {};
        discardOrderRequest.store_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.STORE_ID);
        //discardOrderRequest.ShoppingCartTypeId = 1; // Need to get Dynamically - BA / Service team sync needed
        discardOrderRequest.customer_id = CommonUtil.getStoreIdFromLocalStorageUserProfile(CUSTOMER_PORTAL_CONSTANT.CUSTOMER_ID);
        //discardOrderRequest.product_id = product?.product_price?.ProductId || product?.product_id || product?.product_response_model?.product_price?.ProductId; //11; // My orders lost API based this key will dynamic
        //discardOrderRequest.quantity = 2; // My orders lost API based this key will dynamic
        discardOrderRequest.shopping_cart_type = ShoppingCartType; // My orders lost API based this key will dynamic
        //discardOrderRequest.Id = product.Id || product?.product_response_model?.Id; //1; // My orders lost API based this key will dynamic
        return discardOrderRequest;

    }

    public static updateStoreID(switchingStoreID: number) {
        let currentUser: any = JSON.parse(localStorage.getItem('currentUser'));        
        if(currentUser === null || currentUser === undefined) {
            console.log('StoreID not available in this user');
        } else {             
             currentUser.store_id = switchingStoreID;             
             localStorage.setItem('currentUser', JSON.stringify(currentUser));  
        }
    }

    public static generateRandomListOrder(inputArray: any[]) {
        if(inputArray.length > 0) {
            for(let i = inputArray.length - 1; i > 0; i--) {
                let j = Math.floor(Math.random() * (i + 1));
                [inputArray[i], inputArray[j]] = [inputArray[j], inputArray[i]];
            }
        }
        return inputArray;
    }
}