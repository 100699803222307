import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CommonUtil } from 'src/app/shared/util/common-util';

@Component({
  selector: 'app-delivery-type-dialog',
  templateUrl: './delivery-type-dialog.component.html',
  styleUrls: ['./delivery-type-dialog.component.scss']
})
export class DeliveryTypeDialogComponent implements OnInit {

  public storeId: number;
  constructor(public dialogRef: MatDialogRef<DeliveryTypeDialogComponent>,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      
      this.storeId = CommonUtil.getStoreIdFromLocalStorageUserProfile('store_id'),
      console.log(data)
     }

  ngOnInit(): void {
  }
  updateDeliveryType(type){
    if(this.storeId != 5 && type == 'Instant') {
      this.toastrService.error(this.translateService.instant('myprofile.myorders.label.instantnotavail'),'Error');
    } else {
        this.dialogRef.close(type);
    }
  }
}
